//
// Config values
//
$platform-name: "great potetials";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #00a3e0;
$brand-secondary: #fce300;
$grey: #58595b;
$donate-colour: $brand-secondary;
$bg: #ece9e9;
$green: #00e0ab;
$midyellow: #fffd99;
$darkyellow: #ebd405;

// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo_white.svg";
$logo-width: 175px;
$logo-height: 135px;
$logo-absolute: true;
//
// Typography
//
$font-open-sans: 'Raleway', sans-serif;
$font-family-base: 'Raleway', sans-serif;
$headings-font-family: 'Raleway', sans-serif;
$font-size-base: 1.05rem;
$headings-colour: #58595b;

$font-weight-normal: 400;
$font-weight-bold: 800;
$font-weight-base: $font-weight-normal;

$font-size-h1: 2.25em;
$font-size-h2: 1.85em;
$font-size-h3: 1.65em;
$font-size-h4: 1.35em;
$font-size-h5: 1.15em;
$font-size-h6: 1em;
// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: 600;
// Donate button
$donate-btn-colour: $brand-primary;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: white;
$social-icons-colour: $brand-primary;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 1px solid #e6e6e6;
$card-hover-summary-colour: white;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//
$header-background-colour: $brand-primary;
// Header content

$header-content-margin-bottom: 0.5rem;
$header-content-margin-top: 0;
$header-content-padding-top: 1rem;
// Tagline

// Main call to action
$cta-btn-gap: 20px;
$cta-btn-background-colour: white;
$cta-btn-colour: $brand-primary;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: white;
$header-search-button-icon-colour: $brand-primary;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//
$header-sticky-enabled: true;
$header-sticky-logo-width: $logo-width * 1;
$header-sticky-logo-height: $logo-height * 1;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;
//
// Navigation
//
$nav-background-colour: transparent;
$nav-normal-align-items: right;
$nav-submenu-background-colour: $brand-secondary;
$nav-top-level-item-font-weight: 400;
// Top level items
$nav-top-level-item-colour: white;
$nav-submenu-item-font-weight: bold;
// Submenus

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0rem;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: calc(100% - 6vw);
$carousel-image-overlay: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.75) 0%,
  rgba(0, 0, 0, 0) 65%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 470px;
$carousel-heading-font-size: 2.75rem;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
$carousel-border-radius: 0;
// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-margin-top: 2rem;
$home-intro-colour: $brand-primary;
$home-intro-font-weight: 500;
$home-intro-max-width: 80%;
//
// Home features
//
$home-features-padding-top: $spacer * 3;
//
// Impact stats
//
$impact-stats-padding-y: $spacer * 3;
$impact-stats-background-colour: $brand-secondary;
$impact-stats-colour: $brand-primary;
// $impact-stats-background-image: "impact.jpg";
$impact-stats-background-image-opacity: 1;
// Heading
$impact-stats-heading-font-size: 3rem;
// Individual stat

// Figure
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4em;
// Summary
$impact-stats-summary-font-size: 1.5rem;
//
// Home feeds
//
$home-feeds-background-colour: $bg;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 4;
// Footer admin links
$footer-admin-basket-link-enabled: false;
// Newsletter

// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

$sidebar-mobile-layout: below;

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
$quick-giving-background-colour: $brand-primary;

//
// Home quick giving panel
//
$home-quick-giving-donate-btn-background-colour: white;
$home-quick-giving-donate-btn-colour: $brand-primary;
$home-quick-giving-heading-colour: white;
$home-quick-giving-padding-y: $spacer * 3;
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//
