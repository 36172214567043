.carouselSlideHeading {
  font-weight: 800;
}

.header-search #siteSearch {
  border-width: 0;
}
.carouselSlideHeading {
  font-weight: 800;
}
//add icon to intro
.homeIntro {
  & > *:last-child {
    font-weight: 800;
    color: #00a3e0;
    font-size: 2.75rem;
  }
  h3::before {
    content: "";
    display: block;
    margin: 0 auto 10px;
    width: 60px;
    height: 60px;
    background-image: url(../assets/icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

// .pageHeader {
//   position: relative;
//   &:after {
//     content: "";
//     position: absolute;
//     left: 0;
//     width: 100%;
//     height: 30px;
//     background-image: url(../assets/b_curve.svg);
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     z-index: -1;
//     pointer-events: none;
//     bottom: -20px;
//   }
// }

.pageHeader,
.homefeaturecategory-homeboxquickgiving {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    top: auto;
    background-image: url(../assets/b_curve.svg);
    background-position: center bottom;
    background-size: 102% auto;
    background-repeat: no-repeat;
    z-index: 1;
    pointer-events: none;
    transform: translateY(100%);
    background-position: center top;
    visibility: visible;
    bottom: 1px;
  }
}

@media (max-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    padding-bottom: 1rem;
  }
}

//impact curve
.homefeaturecategory-homeboximpactstats {
  &:before {
    content: "";
    display: block;
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: auto;
    background-size: 102% auto;
    background-repeat: no-repeat;
    background-image: url(../assets/t_curve.svg);
    transform: translateY(-100%);
    background-position: center bottom;
    top: 1px;
    z-index: 2;
  }
}

.homefeaturecategory-homeboximpactstats td h2 {
  font-size: 3em;
  color: $brand-primary;
  margin-bottom: 0;
}

//footer

ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}

.footerBox2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  img {
    margin: 0 20px 30px;
    width: 120px;
  }
}

.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
  p {
    padding: 10px 0 0;
  }
}

//carosel image overlay

.carouselSlideTitle {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/watermark.svg);
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: left;
    opacity: 0.2;
  }
}

//email in header
.socialIcons .email:before {
  content: "\f003";
}

@include max-width(lg) {
  .pageHeader .headerContent {
    margin-bottom: 0;
  }

  .carouselSlideTitle:before {
    content: none;
  }

  .carouselSlideHeading {
    font-size: 2rem;
  }

  .homeIntro > *:last-child {
    font-size: 2rem;
  }
}

.homefeaturecategory-homeboxquickgiving .homepage-quickgiving--copy {
  padding-right: 20px;
}

// Stop logo clashing with nav items
// https://www.bugherd.com/projects/181364/tasks/24
@media (min-width: $nav-breakpoint + 1px) and (max-width: 1150px) {
  .mainLogo {
    width: 113px;
    height: 80px;
  }
}

// Stop these lobby pages clashing into the header curve
// https://www.bugherd.com/projects/181364/tasks/28
body[class^="lobby"] #bodyForm {
  padding-top: 60px;
}


//adjusting th blog details size and colour - SA
.blogDetails {
  .memberName {
    font-size: 1.2em;
    color: $brand-primary;
    font-weight: 800;
  }
    .memberProfilePic {
  width: 40px;
  padding: 0 0 5px;

    }
}


//moving sidebar down the page away from the main title  - SA
.post .contentBlock .postAside {
  padding-top: 3rem;
}

// 4 feeds
@media (min-width: 769px) {
  .homeFeeds {
    flex-wrap: wrap;
    .homeFeed {
      width: calc(50% - 2rem);
      flex: unset;
    }
  }
}

// Carousel padding
.carouselImageMultiple .carouselDetailWrapper {
  padding-left: 0;
  padding-right: 0;
}

// Iframe video - Layout
.postContent .embed-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}